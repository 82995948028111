import { render, staticRenderFns } from "./MarketDetailCard.vue?vue&type=template&id=7e87488a&scoped=true&"
import script from "./MarketDetailCard.vue?vue&type=script&lang=js&"
export * from "./MarketDetailCard.vue?vue&type=script&lang=js&"
import style0 from "./MarketDetailCard.vue?vue&type=style&index=0&id=7e87488a&lang=scss&scoped=true&"
import style1 from "./MarketDetailCard.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e87488a",
  null
  
)

export default component.exports