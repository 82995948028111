<template>
  <div class="card market-detail-card">
    <div class="card-body">
      <div class="box-title d-flex justify-content-between align-items-center mb-3">
        <h5 class="card-title mb-0">ข้อมูลหวย</h5>
        <b-dropdown class="m-0 p-0 btn-edit" size="sm" variant="link" right>
          <template slot="button-content">
            <i class="fas fa-cog"></i>
          </template>
          <b-dropdown-item @click="toggleSetting">
            <template>
              <span class="text-primary"><i class="fas fa-cogs"></i> ตั้งค่า</span>
            </template>
          </b-dropdown-item>

          <b-dropdown-item v-if="status==='Close'" @click="openMarket">
            <template>
              <span class="text-success"><i class="far fa-edit"></i> เปิดรับแทง</span>
            </template>
          </b-dropdown-item>

          <b-dropdown-item v-if="status==='Open'" @click="closeMarket">
            <template>
              <span class="text-warning"><i class="fas fa-ban"></i> ปิดแทง</span>
            </template>
          </b-dropdown-item>

          <b-dropdown-item v-if="status==='Close'" @click="deleteMarket">
            <template>
              <span class="text-secondary"><i class="far fa-trash-alt"></i> ลบหวย</span>
            </template>
          </b-dropdown-item>

          <b-dropdown-item v-if="status==='Delete'" @click="rollbackMarket">
            <template>
              <span class="text-secondary"><i class="fas fa-undo"></i> เรียกคืน</span>
            </template>
          </b-dropdown-item>

          <b-dropdown-item v-if="status==='Delete'" @click="permanentDeleteMarket">
            <template>
              <span class="text-danger"><i class="far fa-trash-alt"></i> ลบถาวร</span>
            </template>
          </b-dropdown-item>

        </b-dropdown>
      </div>
      <div class="row row-status">
        <div class="col-md-4 mb-3">
          <span>สถานะ</span>
        </div>
        <div class="col-md-8 mb-3">
          <span :class="statusTextColor"><i :class="statusIcon"></i> {{ statusText }}</span>
        </div>
      </div>
      <div class="row row-group">
        <div class="col-md-4 mb-3">
          <span>กลุ่ม</span>
        </div>
        <div class="col-md-8 mb-3">
          <span class="text-primary"><i class="fas fa-archive"></i> {{ marketGroup }}</span>
        </div>
      </div>
      <div class="row row-days">
        <div class="col-md-4 mb-3">
          <span>วันที่เปิดแทง</span>
        </div>
        <div class="col-md-8 mb-3">
          <span class="text-primary"><i class="far fa-calendar-alt"></i> {{ openDays }}</span>
        </div>
      </div>
      <div class="row row-times">
        <div class="col-md-4 mb-3">
          <span>เวลาเปิด</span>
        </div>
        <div class="col-md-8 mb-3">
          <span class="text-primary"><i class="far fa-clock"></i> {{ openTime }} - {{ closeTime }}</span>
        </div>
      </div>
      <div class="row row-times">
        <div class="col-md-4 mb-3">
          <span>เวลาออกผล</span>
        </div>
        <div class="col-md-8 mb-3">
          <span class="text-danger"><i class="far fa-clock"></i> {{ announceTime }}</span>
        </div>
      </div>
      <div class="row row-numbers">
        <div class="col-md-4 mb-3">
          <span>เลขเปิดรับแทง</span>
        </div>
        <div class="col-md-8 mb-3">
          <ul class="list-option">
            <li
              v-for="bet in marketOpenBets"
              :key="bet.code"
              v-if="betOptionAvailable(bet.code)"
              :class="{
                'text-success':betOptionAvailable(bet.code),
                'text-secondary':!betOptionAvailable(bet.code),
                'text-danger':betOption(bet.code, 'pay')===0
              }"
            ><i :class="{'fas fa-check':betOptionAvailable(bet.code), 'fas fa-times':!betOptionAvailable(bet.code)}"></i> {{ bet.text }}</li>
          </ul>
        </div>
      </div>
      <div class="row d-none">
        <div class="col-md-4 mb-3">
          <span>รูป</span>
        </div>
        <div class="col-md-8 mb-3">
          <img src="https://member.siam888lot.com/assets/images/flag/th.png" class="market-ing">
        </div>
      </div>
    </div>

    <MarketSettingModal :data="data" :is-show="isShowSettingModal" @close="modalSettingClosed" />
  </div>
</template>
<script>
import { marketStatus, daysOfWeek, marketOpenBets } from '@/configs/market.config'
import MarketService from '@/services/marketService'

import Swal from 'sweetalert2'

import MarketSettingModal from './MarketSettingModal'

export default {
  name: 'MarketDetailCard',
  components: {
    MarketSettingModal
  },
  props: {
    data: {
      type: [Object, String],
      default: null
    }
  },
  data() {
    return {
      isShowSettingModal: false
    }
  },
  computed: {
    status() {
      if(this.data) {
        return this.data.status
      }else{
        return null
      }
    },
    statusConfig() {
      if(this.data){
        const config = marketStatus.find((c)=>{
          return c.code === this.data.status
        })
        if(config) {
          return config
        }else{
          return null
        }
      }else{
        return null
      }
    },
    statusText() {
      const config = this.statusConfig
      if(config){
        return config.text
      }else{
        return ''
      }
    },
    statusTextColor() {
      const config = this.statusConfig
      if(config){
        return config.textColor
      }else{
        return ''
      }
    },
    statusIcon() {
      const config = this.statusConfig
      if(config){
        return config.icon
      }else{
        return ''
      }
    },
    marketGroup() {
      if(this.data) {
        const allGroups = this.$store.state.marketGroups
        const group = allGroups.find((g)=>{
          return g._id === this.data.groupId
        })

        if(group !== undefined) {
          return group.groupTitle
        }else{
          return this.data.groupId
        }
      }else{
        return null
      }
    },
    openDays() {
      if(this.data) {
        const dayThs = this.data.openDays.map((dCode)=>{
          let day = daysOfWeek.find((d)=>{
            return d.code === dCode
          })
          if(day) {
            return day.text
          }else{
            return dCode
          }
        })
        return dayThs.join(', ')
      }else{
        return null
      }
    },
    openTime() {
      if(this.data) {
        return this.data.openTime
      }else{
        return null
      }
    },
    closeTime() {
      if(this.data) {
        return this.data.closeTime
      }else{
        return null
      }
    },
    announceTime() {
      if(this.data) {
        return this.data.announceTime
      }else{
        return null
      }
    },
    marketOpenBets() {
      return marketOpenBets
    }
  },
  methods: {
    toggleSetting() {
      this.isShowSettingModal = true
    },
    modalSettingClosed(needReload) {
      this.isShowSettingModal = false
      if(needReload) {
        this.$emit('reload')
      }
    },
    openMarket() {
      console.log('openMarket')
      MarketService.updateMarketStatus(this.data._id, {
        status: 'Open'
      })
      .then((response)=>{
        if(response.success) {
          this.$emit('reload')
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }else{
          throw new Error()
        }
      })
      .catch(()=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    },
    closeMarket() {
      console.log('closeMarket')
      MarketService.updateMarketStatus(this.data._id, {
        status: 'Close'
      })
      .then((response)=>{
        if(response.success) {
          this.$emit('reload')
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }else{
          throw new Error()
        }
      })
      .catch(()=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    },
    deleteMarket() {
      Swal.fire({
        title: 'ยืนยันการลบ!',
        icon: 'info',
        confirmButtonText: 'ยืนยัน',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก'
      })
      .then((res)=>{
        console.log(res)
        if(res.isConfirmed) {
          MarketService.updateMarketStatus(this.data._id, {
            status: 'Delete'
          })
          .then((response)=>{
            if(response.success) {
              this.$emit('reload')
              this.$notify({
                type: 'success',
                title: 'สำเร็จ!',
                text: 'บันทึกเสร็จเรียบร้อย',
              })
            }else{
              throw new Error()
            }
          })
          .catch(()=>{
            Swal.fire({
              title: 'ผิดพลาด!',
              text: 'ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
        }
      })
    },
    rollbackMarket() {
      this.closeMarket()
    },
    permanentDeleteMarket() {
      Swal.fire({
        title: 'ต้องการลบถาวร?',
        text: 'ลบถาวรจะไม่สามารถเรียกคืนได้อีก',
        icon: 'warning',
        confirmButtonText: 'ยืนยัน',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก'
      })
      .then((res)=>{
        console.log(res)
        if(res.isConfirmed) {
          MarketService.permanentDeleteMarket(this.data._id)
          .then((response)=>{
            if(response.success) {
              this.$router.push({name: 'MarketDashboard'})
              this.$notify({
                type: 'success',
                title: 'สำเร็จ!',
                text: 'ลบออกจากฐานข้อมูลเสร็จเรียบร้อย',
              })
            }else{
              throw new Error()
            }
          })
          .catch((e)=>{
            console.log(e)
            Swal.fire({
              title: 'ผิดพลาด!',
              text: 'ลบข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
        }
      })
    },
    betOption(code, param) {
      if(this.data) {
        return this.data.openBets[code][param]
      }else{
        return null
      }
    },
    betOptionAvailable(code) {
      if(this.data) {
        return this.data.openBets[code]
      }else{
        return false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.card {
  .box-title {
    height: 40px;

    // .btn-edit {
    //   display: none;
    // }
  }
  ul.list-option {
    padding: 0;
    li {
      list-style: none;
      padding: 0;
    }
  }
  .market-ing {
    width: 200px;
  }
}

// .card:hover {
//   .box-title {
//     .btn-edit {
//       display: block;
//     }
//   }
// }
</style>
<style lang="scss">
.market-detail-card {
  button.dropdown-toggle::after {
    display: none;
  }
  .dropdown-menu.dropdown-menu-right {
    li {
      font-size: 90%;
    }
  }
}
</style>
